import React from "react"
import { graphql } from "gatsby"
import { SiteMetadata, Products } from "../../components"
import { Layout } from "../../layouts/Layout"

import { Container, Row, Col } from "react-bootstrap"

export default ({ data }) => {
  return (
    <Layout>
        <SiteMetadata 
          title="Продукция" 
          canonical="/shop/"
          description="Натуральный мед нескольких видов и другие продукты пчеловодства: cвечи из вощины, шоколад с добавлением меда, натуральная косметика, перга, прополис и готовые подарочные наборы." 
        />
        <Container className="mt-5">
          <Row>
            <Col className="text-center">
              <h1 className="d-inline-block mb-4 beeright-small">Продукция Пчелиного Хуторка</h1>
            </Col>
          </Row>
        </Container>  
        <Products nodes={data.items.nodes} />
    </Layout>
  )
}


export const query = graphql`
  query ShopIndexQuery {    
    items: allProductsJson {
      nodes {
        id
        name
        price
        slug
        slug_category
        shortdesc
        images {
          childImageSharp {
            fluid(maxWidth: 350, maxHeight: 350, cropFocus: NORTH) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        feature {
          varesi {
            price
          }
        }
      }
    }
  }
`
